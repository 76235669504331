import React from "react"
import { Link } from "gatsby";
import { Picture } from 'react-responsive-picture';

import Layout from '../components/layout';
import { TextGradient } from '../components/_text/_text_gradient';
import { ShareBlog } from '../components/_share/_share_blog';
import CalorexDesign from "../components/svg/commons/calorex_design";

import '../styles/pages/generic/_generic.scss';
import SliderImageLink from "../components/sliders/slider_image_link";

const GenericPage = (props) => {

	let item = props.pageContext.item;
	let color_start = '#d70b2d';
	let color_end = '#bc0c2f';
	let metas = {
		title: 'Calorex',
		description: 'Calorex ',
		image: ''
	}
	let metas_from_prismic = get_item_from_data(item.node.data.body, "metas");

	if (metas_from_prismic) {
		metas.title = metas_from_prismic.primary.title.text
		metas.description = metas_from_prismic.primary.description.text
		metas.image = metas_from_prismic.primary.image_share.url
	}

	function get_item_from_data(_body, _type) {
		let found = false;
		let item_count = 0;
		if (_body === null) return null;
		while (!found && item_count < _body.length) {
			let bodyitem = _body[item_count];
			if (bodyitem.slice_type === _type) {
				return bodyitem;
			}
			item_count++;
		}
		return null;
	}
	function get_detail(_item) {
		return (
			<div className="blog_detail_container">
				<div className="blog_detail_content_top blog_detail_content" dangerouslySetInnerHTML={{ __html: _item.node.data.content.html }}></div>
					{_item.node.data.body !== null ?
						_item.node.data.body.map((_block, index) => {
							switch (_block.slice_type) {
								case 'copy':
									return (
										<TextGradient key={`text-gradiente-generic__${index}`} _className="blog_detail_content_copy" _text={_block.primary.title1.text} _color_start={color_start} _color_end={color_end}></TextGradient>
									)
								case 'texto':
									return (
										<div key={`generic-text__${index}`} className="blog_detail_content_bottom blog_detail_content" dangerouslySetInnerHTML={{ __html: _block.items[0].description.html }}></div>
									)
								case 'slider_imagen_link':
									return <SliderImageLink key={`slider_imagen_link__${index}`} _items={_block.items}></SliderImageLink>
								default: return (null);
							}
						})
					: null
				}
			</div>
		);
	}

	const buildHeader = () => { 
		return (
			<Picture
				sources={[
					{
						srcSet: item.node.data.main_image.xs.url,
						media: "(max-width: 767px)",
					},
					{
						srcSet: item.node.data.main_image.lg.url,
						media: "(max-width: 1365px)",
					},
					{
						srcSet: item.node.data.main_image.url,
						media: "(max-width: 1919px)",
					},
					{
						srcSet: item.node.data.main_image.url,
						media: "(min-width: 1920px)",
					},
				]}
			/>
		)
	}

	const buildLinkHeader = (link) => {
		if (link.target === '_blank') {
			return (
				<a href={link.url} target={link.target} rel="noopener noreferrer" className="genericpage_carrousel_link">
					{buildHeader()}
				</a>
			)
		} else { 
			return (
				<Link to={link.url} className="genericpage_carrousel_link">
					{buildHeader()}
				</Link>
			)
		}
	}

	return (
		<Layout>
			<div className="genericpage-container">
				<div className="genericpage_header_stage">
					<div className="genericpage_carrousel">
						<div className="genericpage_carrousel_textcontainer">
							<div className="genericpage_carrousel_icon">
								{item.node.uid === 'calorex-design' ? <CalorexDesign></CalorexDesign> : null}
							</div>
							<TextGradient _text={item.node.data.title.text} _className='genericpage_carrousel_item__title' _color_start={color_start} _color_end={color_end}></TextGradient>
						</div>
						<div className="genericpage_carrousel_imagecontainer">
							{ 
								item.node.data.link_header !== null ?
									buildLinkHeader(item.node.data.link_header)
								: buildHeader()
							}
						</div>
					</div>
				</div>
				<div className="genericpage_body">
					<div className='genericpage_body_main'>
						<div className="genericpage_detail_share">
							<ShareBlog _fb_to={`/${item.node.uid}`} _tw_to={`/${item.node.uid}`} _color_start={color_start} _color_end={color_end} _section='genericpage'></ShareBlog>
						</div>
						<TextGradient _text={item.node.data.title.text} _className='genericpage_carrousel_item__title' _color_start={color_start} _color_end={color_end}></TextGradient>
						{get_detail(item)}
					</div>
				</div>
			</div>
		</Layout>
	)
}
export default GenericPage
