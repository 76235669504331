import React, { useEffect, useState, useRef } from "react"
import { Picture } from "react-responsive-picture";
import { Link } from "gatsby"

import DetailLogo from "../../content/model_detail/detail_logo";
import SliderManager from "./_slider_manager";
import SliderArrow from "./slider_arrow";
import { CssClassManager } from "../_helpers/_css_class_manager";

import '../../styles/_components/_sliders/_slider_image_link.scss'

const SliderImageLink = ({ _items }) => { 
  
  let sliderArrow = new SliderArrow()
  const sliderImageLinkRef = useRef(null)
  const bannerSectionRef = useRef(null)
  const [slidesToShow, setSlidesToShow] = useState()
  const [direction, setDirection] = useState()

  useEffect(() => {
    const handleResize = () => {
      const winWidth = window.innerWidth;
      if (winWidth < 768) {
        setSlidesToShow(1);
      } else if (winWidth >= 768 && winWidth < 1280) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }

      if (!bannerSectionRef.current) return;
      sliderArrow.init(bannerSectionRef);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }

  }, [sliderArrow])

  useEffect(() => {
    let cssClassManager = new CssClassManager()
    setDirection(null)

    if (direction === "next") {
      cssClassManager.remove_class(sliderImageLinkRef.current, "on_prev")
      cssClassManager.add_class(sliderImageLinkRef.current, "on_next")
    } else if (direction === "prev") {
      cssClassManager.remove_class(sliderImageLinkRef.current, "on_next")
      cssClassManager.add_class(sliderImageLinkRef.current, "on_prev")
    }
  }, [direction])

  const nameLogo = `LÍNEA <em>FORTIS</em>`

  return (
    <div className="slider-image-link__container model_detail_section" ref={bannerSectionRef}>
      <div className="slider-image-logo">
        <DetailLogo _name={`${nameLogo}`} _title="DISTRIBUIDOR OFICIAL"></DetailLogo>
      </div>
      <div className="slider-image-link__slider" ref={sliderImageLinkRef}>
        { 
          _items && _items.length > 0 &&
          <SliderManager itemsToShow={slidesToShow}>
            { 
              _items.map((_item, _index) => {
                const {image, link} = _item
                return (
                  <div key={`card-image-link__${_index}`} className="card-image-link">
                    <Picture className="card-image-link__image" src={image.url} alt={image.alt} />
                    <div className="card-image-link__link">
                      {
                        link.target === "_blank" ?
                          <a href={link.url} target={link.target} rel="noreferrer">Ver Más</a>
                        : <Link to={link.url}>Ver Más</Link>
                      }
                    </div>
                  </div>
                )
              }) 
            }
          </SliderManager>
        }
    </div>
  </div>
  )
}

export default SliderImageLink
